import React from "react"
import { Link } from "gatsby"

import styled from "styled-components";
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { isMobile } from "react-device-detect";

import Page from "../../components/layout/Page"
import PageHeader from "../../components/layout/PageHeader"
import Container from "../../components/layout/Container"
import SEO from "../../components/seo"
import GoogleImage from "../../components/GoogleImage";

import { devices, storage } from "../../settings"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import HoverButton from "../../components/buttons/HoverButton";

const Content0 = styled.div`
    padding: 10px;

    @media ${devices.mobile} {
        padding: 0px;
    } 
`
const Content1 = styled.div`
    padding-left: 20px;

    @media ${devices.tablet} {
        padding-left: 10px;
    }      
    @media ${devices.mobile} {
        padding-left: 0px;
    } 

    p {
        @media ${devices.mobile} {
            margin: 0px;

        }     
    }

    span {
        @media ${devices.mobile} {
            display: none;
        } 
    }
`
const Content2 = styled.div`
    padding-left: 20px;
    background-image: url(${storage.url}2020/tiles/grey3.jpg);
    border-top: 3px solid #e8e8e8;
    margin-top: 80px;

    > div {
        margin-top: -50px;
    }

    @media ${devices.tablet} {
        padding-left: 0px;
    }     
    @media ${devices.mobile} {
        padding-left: 0px;
        margin-left: -10px;
        margin-right: -10px;
    } 
`


const Products = styled.div`
  padding: 20px 25px;
  
  @media ${devices.tablet} {
    padding: 0px 10px;
  }  
`
const Product = styled.section`
  header {
      background-color: #eee;
      box-shadow: 0px 6px 10px #ccc;
      padding: 10px 15px;
      display: flex;
      align-items: baseline;
      border: 1px solid #ccc;
      border-radius: 5px;

      @media ${devices.tablet} {
        display: block;
      }  
      @media ${devices.mobile} {
        display: block;
        background-color: transparent;
        box-shadow: none;
        border: 0px;
      }  

      > * {
        margin: 0px;
      }

      > h2 {
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
        margin-right: 15px;
        font-size: 1.5em;

        @media ${devices.mobile} {
            font-size: 20px;
        }  
      }
      > p {
          font-size: 18px;

          @media ${devices.mobile} {
            font-size: 14px;
          }  
      }
    
  }

  margin-top: 0px;
  margin-bottom: 25px;

  @media ${devices.mobile} {
    padding: 0px;
    background-color: white;
    box-shadow: 0px 6px 10px #ccc;
    border: 1px solid #ccc;
    border-radius: 5px;
  }  

  a {
    color: black;
  }

  > div {
    flex-basis: 50%;
  }
  div.images {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 6px 10px #ccc;
    display: flex;
    justify-content: space-between;
    margin: 0px 50px;

    @media ${devices.tablet} {
        margin: 0px 20px;
    }  
  
    @media ${devices.mobile} {
      display: block;
      margin: 10px 0px 0px 0px;
      border: 0px;
      box-shadow: none;
    }  
  
    > a {
      display: block;
      flex-basis: 47%;
      padding: 30px;      
      
      @media ${devices.mobile} {
        display: flex;
        align-items: center;
        flex-flow: row-reverse;
        padding: 10px 0px;   
        border-top: 1px solid #eee;   
      }  

      img {
        display: block;
        margin: 0px auto;
        width: 100%;
        height: auto;

        @media ${devices.mobile} {
          margin: 5px;
          width: 150px;
          height: 75px;
        }        
      }

      > div {
        @media ${devices.mobile} {
            padding: 5px;
        }    
      }
    }

    div.info {
        text-align: right;

        @media ${devices.mobile} {
            display: none;
        }  
    }
    div.arrow {
        display: none;
        padding: 10px;
        font-size: 20px;
        color: #999;

        @media ${devices.mobile} {
            display: block;
        }  
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      margin: 0px;
      text-align: center;

      @media ${devices.mobile} {
        text-align: left;
        font-sizeL 15px;
      }  
    }
    small {
        display: block;
        font-size: 14px;
        color: #999;
        font-weight: 200;
        text-align: center;

        @media ${devices.mobile} {
            text-align: left;
            font-size: 12px;
        }      
    }

  }
`

const LitLettersShapesPage = () => {
  return (
    <>
      <SEO page="products" title="Lit Channel Letters and Logo Shape Signs with Easy Installation" />
      <div>
        <Page 
          transparent
          padding={0}
          breadcrumb={[
            {title: "Home", url:"/"},
            {title: "Products", url:"/products/"},
            {title: "Lit Channel Letters And Logos", url:null},
          ]}
          containerProps={{
            mode: "full"
          }}
        >
          <Content0>
            <Container>
                <PageHeader
                    title = "Lit Channel Letters &amp; Logos"
                />
            </Container>
          </Content0>

          <Content1>
            <Container>
            <p>
              Commonly called "Channel Letters" or “Face Lit Letters” The way the signs are mounted and how they are lit determines 
              their further description. For instance, if the sign is lit through the front it is called Face Lit or BackLit. If the sign lights out the back it is called Halo Lit or Reverse Lit.
            </p>
            <span>
                <p>
                  This can get confusing so we have illustrated the sign types here so you can choose a product to continue learning about.
                </p>
            </span>
            </Container>
          </Content1>

          <Content2>
            <Container>
                <Products>
                    <Product>
                        <header>
                            <h2>Face Lit Channel Letters &amp; Logos</h2>
                            <p>Internally Lit with LEDS through the plastic translucent face.</p>
                        </header>
                        <div className="images">
                        <Link to="/products/face-lit-channel-letters-and-logos/">
                            <div className="arrow">
                                <FontAwesomeSvgIcon icon={faChevronRight} />
                            </div>
                            <div>
                              <h3>EZLit Mounting System</h3>
                              <small>* Our Patented Plug-n-Play Technology</small>
                            </div>
                            <GoogleImage root src={"2020/products/channel-letters-and-shapes.jpg?v1"} required lazy alt="Face Lit EZLit Mounting System" width={isMobile ? 150:375} height={isMobile ? 75:181} />
                            <div className="info">
                                <HoverButton 
                                    color="blue"
                                    label="Learn More"
                                    icon={faChevronRight}
                                />
                            </div>
                        </Link>
                        <Link to="/products/face-lit-channel-letters-and-logos-on-raceway/">
                            <div className="arrow">
                                <FontAwesomeSvgIcon icon={faChevronRight} />
                            </div>
                            <div>
                                <h3>Raceway Mounting System</h3>
                                <small>&nbsp;</small>
                            </div>
                            <GoogleImage root src={"2020/products/channel-letters-and-shapes-on-raceway.jpg?v1"} required lazy alt="Face Lit on Raceway Mount" width={isMobile ? 150:375} height={isMobile ? 75:181} />
                            <div className="info">
                                <HoverButton 
                                    color="blue"
                                    label="Learn More"
                                    icon={faChevronRight}
                                />
                            </div>
                        </Link>
                    </div>
                    </Product>

                    <Product>
                        <header>
                            <h2>Face &amp; Halo Lit Channel Letters &amp; Logos</h2>
                            <p>Double Lit with LEDs through the plastic translucent face and transparent plastic back.</p>
                        </header>
                        <div className="images">
                        <Link to="/products/face-and-halo-lit-channel-letters-and-logos/">
                            <div className="arrow">
                                <FontAwesomeSvgIcon icon={faChevronRight} />
                            </div>
                            <div>
                                <h3>EZLit Mounting System</h3>
                                <small>* Our Patented Plug-n-Play Technology</small>
                            </div>
                            <GoogleImage root src={"2020/products/face-and-halo-channel-letters-and-shapes.jpg?v1"} required lazy alt="Face and Halo Lit EZLit Mounting System" width={isMobile ? 150:375} height={isMobile ? 75:181} />
                        
                            <div className="info">
                                <HoverButton 
                                    color="blue"
                                    label="Learn More"
                                    icon={faChevronRight}
                                />
                            </div>
                        </Link>
                        <Link to="/products/face-and-halo-lit-channel-letters-and-logos-on-raceway/">
                            <div className="arrow">
                                <FontAwesomeSvgIcon icon={faChevronRight} />
                            </div>
                            <div>
                                <h3>Raceway Mounting System</h3>
                                <small>&nbsp;</small>
                            </div>
                            <GoogleImage root src={"2020/products/face-and-halo-channel-letters-and-shapes-on-raceway.jpg?v1"} required lazy alt="Face and Halo Lit on Raceway Mount" width={isMobile ? 150:375} height={isMobile ? 75:181} />
                            <div className="info">
                                <HoverButton 
                                color="blue"
                                label="Learn More"
                                icon={faChevronRight}
                                />
                            </div>
                        </Link>
                    </div>
                    </Product>

                    <Product>
                        <header>
                            <h2>Halo Lit Channel Letters &amp; Logos</h2>
                            <p>Lit with LEDs through transparent back creating a halo around the letters and shapes.</p>
                        </header>
                        <div className="images">
                        <Link to="/products/halo-lit-channel-letters-and-logos/">
                            <div className="arrow">
                                <FontAwesomeSvgIcon icon={faChevronRight} />
                            </div>
                            <div>
                                <h3>EZLit Mounting System</h3>
                                <small>* Our Patented Plug-n-Play Technology</small>
                            </div>
                            <GoogleImage root src={"2020/products/halo-channel-letters-and-shapes.jpg?v1"} required lazy alt="Halo Lit EZLit Mounting System" />
                            <div className="info">
                                <HoverButton 
                                    color="blue"
                                    label="Learn More"
                                    icon={faChevronRight}
                                />
                            </div>
                        </Link>
                        <Link to="/products/halo-lit-channel-letters-and-logos-on-raceway/">
                            <div className="arrow">
                                <FontAwesomeSvgIcon icon={faChevronRight} />
                            </div>
                            <div>
                                <h3>Raceway Mounting System</h3>
                                <small>&nbsp;</small>
                            </div>
                            <GoogleImage root src={"2020/products/halo-channel-letters-and-shapes-on-raceway.jpg?v1"} required lazy alt="Halo Lit on Raceway Mount" />
                            <div className="info">
                                <HoverButton 
                                    color="blue"
                                    label="Learn More"
                                    icon={faChevronRight}
                                />
                            </div>
                        </Link>
                    </div>
                    </Product>
                </Products>           
            </Container>
          </Content2>

        </Page>
      </div>
    </>
  )
}

export default LitLettersShapesPage
